@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.style-about {
    background-color:#b2c5b2;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.style-picture {
    flex: 1;
    flex-basis: 33.33%;
}

.style-picture img {
    width: 100%;
    height: auto;
    min-height: 100vh;
}

.style-info {
    flex: 4;
    padding-left: 20px;
}

.style-about-heading {
    font-family: 'Baskervville', serif; 
    font-size: 100px;
    letter-spacing: -4px;
    padding-top: 15px;
    color: #1b2727;
    padding-left: 20%;
}

.style-paragraph-container {
    display: flex;
}

.style-main-paragraph {
    font-family: 'Raleway', sans-serif;
    font-size: 26px;
    padding-top: 10%;
    padding-left: 7%;
    padding-right:20px;
    color: #1b2727;
    flex: 1 0 32%;
}

.style-second-paragraph {
    padding-right: 40px;
    padding-top: 4%;
    color: #1b2727;
    font-family: 'EB Garamond', serif;
    flex: 1 0 40%;
    text-indent: 15px;
    font-size: 18px;
}


