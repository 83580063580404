@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap'); 

.style-tea-main {
  min-height: 100vh;
  background-color: #f2edd7ff;
  display: flex;
  flex-wrap: wrap;
  }

.style-tea-heading {
  font-family: 'Baskervville', serif; 
  font-size: 60px;
  letter-spacing: -2px;
  color: #755139FF;
  padding-top: 3%;
  padding-left: 30%;
}

.style-gallery {
  flex: 1;
  padding-top:2%;
  max-height: 100vh;
}

.style-tea-info {
  flex: 2;
}

.style-tea-paragraph {
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 4%;
  color: #755139FF;
  font-family: 'EB Garamond', serif;
  flex: 1 0 40%;
  text-indent: 15px;
  font-size: 20px;
}

.style-tea-paragraph-2 {
  margin-left: 5%;
  margin-right: 5%;
  color: #755139FF;
  font-family: 'EB Garamond', serif;
  flex: 1 0 40%;
  text-indent: 15px;
  font-size: 20px;
}

.style-symbols-container {
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.style-item {
    text-align: center;
  }

  .style-symbols {
    max-height: 100px;
  }

  .style-caption {
    display: block;
    text-align: center;
    font-family: 'Baskervville', serif; 
    font-size: 12px;
    color: #000000;
  }