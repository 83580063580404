@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.style-footer-main {
    min-height:5vh;
    background-color:#1b2727;
}

.style-footer {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    text-align: center;
}