@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.style-landing {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.style-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.style-content {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.style-sub-heading {
  font-family: Rogthem;
  letter-spacing: 4px;
  color: white;
  font-size: 100px;
}
.style-heading {
  font-family: 'Bebas Neue', cursive;
  font-size: 30px;
  color: white;
  margin-top: 1vh;
}

.style-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

@font-face {
    font-family: 'Rogthem';
    src: url('../../Rogthem.woff2') format('woff2'),
        url('../../Rogthem.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }