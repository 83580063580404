@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.style-maincontactus {
    min-height:91vh;
    display: flex;
    flex-wrap: wrap;
}

.style-contactus {
    background-color:#b2c5b2;
    flex: 1;
}

.style-form{
    background-color: #3c5148;
    flex: 2;
}

.style-inner-form {
    margin-top: 15%;
    margin-left: 30%;
    margin-right: 25%;
}

.style-heading-contactus {
    color: #1b2727;
    text-align: center;
    padding-top: 10%;
    padding-right: 5%;
    font-family: 'Baskervville', serif;
    font-size: 70px;
    letter-spacing: -4px;
}

.style-para-contactus {
    color: #1b2727;
    font-family: 'EB Garamond', serif;
    padding-left: 10%;
    padding-right: 15%;
    padding-top: 10%;
    text-align: center;
}

.style-input-form {
    display: flex;
    flex-direction: column;
}

.style-label {
    color: #D5DDDF;
    transition: .25s ease;
}

#style-input {
    color: #D5DDDF;
    border: 1px solid #b2c5b2;
    background: transparent;
}

.style-contact-info {
    display: inline;
    padding-top: 4%;
}

.style-icon {
    color: #1b2727;
    padding-right: 4%;
    padding-top: 4%;
}

.style-details-block {
    padding-top: 20%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    margin-left: 20%;
    margin-right: 30%;
}

.style-button-div {
    padding-top: 2%;
    text-align: center;
}

.style-button {
    color: #D5DDDF;
    border-color: #b2c5b2;
}