.bg {
    background-color: #1b2727;
    max-height: 100vh;
    min-height: 100vh;
    position: relative;
}

.smoke-loader {
    width: 100px;
    height: 100px;
    display: inline-block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 50%);
    }
    .coffee-cup{
    height:80px;
    margin-left: auto;
    margin-right: auto;
    }
    
    .smoke-wave {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0px;
    left: 0px;
    }
    
    .smoke1 {
    width: 6px;
    height: 30px;
    float: left;
    margin-right: 6px;
    margin-left: 4px;
    border-radius: 3px;
    }
    .smoke1:before, .smoke1:after {
    content: "";
    background: #B2C5B2;
    position: absolute;
    animation: load .9s infinite reverse ease-in-out;
    border-radius: 3px;
    }
    .smoke2 {
    width: 6px;
    height: 15px;
    float: left;
    margin-right: 6px;
    margin-left: 4px;
    border-radius: 3px;
    }
    .smoke2:before, .smoke2:after {
    content: "";
    background: #B2C5B2;
    position: absolute;
    animation: load .6s infinite reverse ease-in-out;
    border-radius: 3px;
    margin-top: 5px;
    }
    .smoke3 {
    width: 6px;
    height: 30px;
    float: left;
    margin-right: 6px;
    margin-left: 4px;
    border-radius: 3px;
    }
    .smoke3:before, .smoke3:after {
    content: "";
    background: #B2C5B2;
    position: absolute;
    animation: load .9s infinite .5s reverse ease-in-out;
    border-radius: 3px;
    }
    
    @keyframes load {
    0% { top: 0; height: 30px; width: 6px; opacity: 0; }
    50% {opacity: 1;  }
    100% { top: 29px; height: 15px; width: 6px; opacity: 0;}
    }