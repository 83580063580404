@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.style-navbar {
    box-sizing: border-box;
    background-color: transparent;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
}

.style-navbar.active {
    background-color: rgba(0, 0, 0, 0.9);
    transition: .25s ease;
}

.style-logo {
    height:auto;
    width: 108px;
}

.style-navbar-scrolled {
    background-color: black;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.style-navitem {
    padding-right: 50px;
}

.style-subheading {
    font-family: 'Abel', sans-serif;
    margin-right: 20px;
}

.style-navlink,
.style-navlink:focus
{
    color: #b2c5b2;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #54b3d6;
    margin: 0 -.25rem;
    padding: 0 .25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.style-navlink:hover {
    box-shadow: inset 100px 0 0 0 #101820FF;
    color: #F2AA4CFF;   
}